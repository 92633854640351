<template>
  <div>
    <v-container
      class="v-container-fluid"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <!-- button return -->
          <v-btn
            @click="$router.go(-1)"
            text
            class="pl-0"
          >
            <v-icon
              class="icon-return"
              size="15px"
            >
              mdi-arrow-left
            </v-icon>
            <span class="text-return mon-regular">{{ texts.article.buttonReturn }}</span>
          </v-btn>
          <!-- content title and filters -->
          <div class="mt-5">
            <v-row>
              <v-col
                cols="12"
                lg="4"
                md="4"
                sm="12"
              >
                <div class="display-flex align-items-center height-100">
                  <p class="title-section mon-bold">{{ texts.article.titleSection }}</p>
                </div>
              </v-col>
              <v-col
                cols="12"
                lg="2"
                md="2"
                sm="4"
              >
                <div class="display-flex align-items-center height-100">
                  <v-select
                    v-model="sCategoryIdFilter"
                    :label="texts.article.textCatalog"
                    outlined
                    color="#000000"
                    dense
                    class="global-inputs mon-regular"
                    :items="aCatalogs"
                    item-text="sName"
                    item-value="sCategoryId"
                    clearable
                    @change="returnSubcatalogs"
                  ></v-select>
                </div>
              </v-col>
              <v-col
                cols="12"
                lg="2"
                md="2"
                sm="4"
              >
                <div class="display-flex align-items-center height-100">
                  <v-select
                    v-model="sSubcategoryIdFilter"
                    :label="texts.article.textSubcatalog"
                    outlined
                    color="#000000"
                    dense
                    class="global-inputs mon-regular"
                    :items="aSubcatalogs"
                    item-text="sSubcategoryName"
                    item-value="sSubcategoryId"
                    clearable
                    @change="returnCatalogs"
                  ></v-select>
                </div>
              </v-col>
              <v-col
                cols="12"
                lg="4"
                md="4"
                sm="4"
              >
                <div class="display-flex align-items-center height-100">
                  <v-text-field
                    v-model="sSearch"
                    solo
                    :label="texts.article.textSearch"
                    append-icon="mdi-magnify"
                    class="global-inputs mon-regular mod-input"
                    outlined
                    color="#000000"
                    dense
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: "",
    },
  },
  name: "ArticleSearchbarLayout",
  data() {
    return {
      //VARIABLES
      aCatalogs: [],
      aSubcatalogs: [],
      sCategoryIdFilter: "",
      sSubcategoryIdFilter: "",
      sSearch: "",
      sFilter: "",
      empty: "",
    };
  },
  beforeMount() {
    if (
      this.$route.params.idCategory == "all" &&
      this.$route.params.idSubcategory == "b87bbcf26818fb2e6262fffeab402f03"
    ) {
      this.sCategoryIdFilter = "";
      this.sSubcategoryIdFilter = "";
    } else {
      this.sCategoryIdFilter = this.$route.params.idCategory;
      this.sSubcategoryIdFilter = this.$route.params.idSubcategory;
    }

    this.getCatalogs();
    this.getSubcatalogs();
  },
  methods: {
    getCatalogs: function () {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/categories`, {
        headers: {},
        params: {},
      })
        .then((response) => {
          this.aCatalogs = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    returnSubcatalogs: function () {
      this.getSubcatalogs();
    },
    returnCatalogs: function (e) {
      if (e !== null) {
        this.sCategoryIdFilter = this.aSubcatalogs.find((res) => {
          return res.sSubcategoryId == e;
        }).sCategoryId;
        this.getSubcatalogs(this.sCategoryIdFilter);
      }
    },
    getSubcatalogs: function (e) {
      let res;
      if (this.sCategoryIdFilter == null) {
        res = "";
      } else {
        res = this.sCategoryIdFilter;
      }

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/subcategories?sCategoryId=${res}`,
        {
          headers: {},
          params: {},
        }
      )
        .then((response) => {
          this.aSubcatalogs = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        if (
          this.$route.params.idCategory == "all" &&
          this.$route.params.idSubcategory == "b87bbcf26818fb2e6262fffeab402f03"
        ) {
          this.sCategoryIdFilter = "";
          this.sSubcategoryIdFilter = "";
        } else {
          this.sCategoryIdFilter = this.$route.params.idCategory;
          this.sSubcategoryIdFilter = this.$route.params.idSubcategory;
        }
        // this.sCategoryIdFilter = this.$route.params.idCategory;
        // this.sSubcategoryIdFilter = this.$route.params.idSubcategory;
        this.getCatalogs();
        this.getSubcatalogs();
      }
    },
    // sSearch: function () {
    //   this.$store.commit("setSearch", this.sSearch);
    // },
    sSearch: lodash.debounce(function (val) {
      this.$store.commit("setSearch", this.sSearch);
    }, 500),
    sCategoryIdFilter: function () {
      if (this.sCategoryIdFilter == null) {
        this.$store.commit("setCategoryIdFilter", "");
      } else {
        this.$store.commit("setCategoryIdFilter", this.sCategoryIdFilter);
      }
    },
    sSubcategoryIdFilter: function () {
      if (this.sSubcategoryIdFilter == null) {
        this.$store.commit("setSubcategoryIdFilter", "");
      } else {
        this.$store.commit("setSubcategoryIdFilter", this.sSubcategoryIdFilter);
      }
    },
  },
};
</script>

<style>
.mod-input .v-input__slot {
  padding-right: 0px !important;
}

.mod-input .v-input__icon {
  background: transparent linear-gradient(228deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  height: 40px;
  width: 40px;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}
</style>

<style scoped>
.title-section {
  text-align: left;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  font-size: 35px;
  margin-bottom: 0px;
}

.icon-return {
  border-radius: 100%;
  width: 27px;
  height: 27px;
  background: transparent linear-gradient(265deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
}

.text-return {
  text-transform: initial;
  margin-left: 5px;
  font-size: 16px;
}

@media (max-width: 960px) {
  .hidden-from-tablet {
    display: none;
  }
}
</style>